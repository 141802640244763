
import {defineComponent} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import {Api} from "@/store/enums/StoreEnums";
import {dateformat, formatCurrency} from "@/core/filters/filters";
import ExtendRoleModal from "@/views/employee/modals/ExtendRoleModal.vue";


export default defineComponent({
  name: "EmployeeDocuments",
  components: {
    ExtendRoleModal
  },

  setup() {
    return {
      dateformat,
      formatCurrency
    }
  },
  data() {
    return {
      employee: {},
      currentRole: {},
      loaded: false,
      currentRoleLoaded: false,
      employeeId: this.$route.params.id_emp,

    }
  },
  mounted() {
    this.getCurrentEmployee();
    setCurrentPageBreadcrumbs("Zaměstnanci", ["Detail", "Informace"]);
  },
  methods:
    {
      getCurrentEmployee() {
        ApiService.get(Api.GET_EMPLOYEE, {employee_id: this.employeeId}).then(response => {
          this.employee = response.data
          this.loaded = true;
          ApiService.get(Api.GET_CURRENT_EMPLOYEE_ROLE, {employee_id: this.employeeId})
            .then(response => {
              this.currentRole = response.data;
              this.currentRoleLoaded = true;
            })
        })
      }
    },

})
;
