
import {defineComponent, ref} from "vue";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {Api} from "@/store/enums/StoreEnums";
import apiService from "@/core/services/ApiService";
import {castData} from "@/core/data/data_prepare";

export default defineComponent({
  name: "ExtendRoleEmployeeModal",
  props: {
    idEmployee: {type: String, default: ""},
    id: {type: Number, default: 0},
    roleId: {type: Number, default: 0}
  },
  data() {
    return {
      formData: {
        work_end_plan_date: "",
        extend_date: "",
        employee_id: this.$props.idEmployee,
        id: this.$props.id,
        role_id: this.$props.roleId,
      },
      loading: false,
    }
  },
  methods: {
    submit() {
      let dataFormat = {
        work_end_plan_date: 'date',
        extend_date: 'date',
        employee_id: 'number',
        id: 'number',
        role_id: 'number'
      };
      let formRef;
      let addExtendRoleModalRef;
      if (this.$refs.formRef && this.$refs.addExtendRoleModalRef) {
        formRef = ref(this.$refs.formRef);
        addExtendRoleModalRef = ref(this.$refs.addExtendRoleModalRef);
      } else return;
      if (!formRef.value || !addExtendRoleModalRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {

          let data = castData(this.formData, dataFormat);
          console.log(data);

          this.loading = true;
          apiService.postFrom(Api.ROLE_EMPLOYEE, data).then(() => {
            this.loading = false;
            hideModal(addExtendRoleModalRef.value);

          }).catch(({response}) => {
            console.log(response);
            Swal.fire({
              text: response.data.ErrorMessage,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Něco se pokazilo!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        }
      });
    },
  },
});
